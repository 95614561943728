import React from 'react';
import styled from 'styled-components';


const LogoStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  font-family: Pacifico, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 100px;
  height: 20em;
  width: 20em;
  font-size: clamp(5px, 0.85vw, 100px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 2.05);
  border: 10px solid;
  border-image: linear-gradient(#00767A, #5759d6, #eeafe4, #00767A) 20;
  background-color: #222222;
  background: repeating-linear-gradient(45deg, #2b2b2b 0%, #2b2b2b 10%, #222222 0%, #222222 50%) 0 / 15px 15px;
  text-align: center;
  transform: translateY(-5.5em);
  h1 {
    color: var(--blue);
    border: 0.2rem solid #fff;
    border-radius: 2rem;
    display: grid;
    grid-template-columns: repeat(5rem);
    grid-gap: .25em;
    text-align: left;
    padding: 2rem;
    animation: flicker 1.5s infinite alternate;
  }
  .logo-word {
    color: var(--neon-green);
    animation: neon .08s ease-in-out infinite alternate;
  }
  .you-light {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    color: var(--green);
  }

  .you-light:hover {
    -webkit-animation: neonyou 1.5s ease-in-out infinite alternate;
    -moz-animation: neonyou 1.5s ease-in-out infinite alternate;
    animation: neonyou 1.5s ease-in-out infinite alternate;
    color: var(--neon-green);
  }

  @media (max-width: 600px) {
    width: 60em;
    h1 {
      grid-template-columns: auto auto auto auto;
      grid-gap: 1.5em;
      justify-items: center;
      padding: 2rem;
    }
    .you-light {
      -webkit-animation: neonyou 1.5s ease-in-out infinite alternate;
      -moz-animation: neonyou 1.5s ease-in-out infinite alternate;
      animation: neonyou 1.5s ease-in-out infinite alternate;
      color: var(--neon-green);
    }
  }

  @keyframes flicker {
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      box-shadow:
        0 0 .5rem #fff,
        inset 0 0 .5rem #fff,
        0 0 1rem var(--pink),
        inset 0 0 1rem var(--pink),
        0 0 2rem var(--pink),
        inset 0 0 2rem var(--pink);        
    }
    20%, 24%, 55% {        
      box-shadow: none;
    }    
  }

  @-webkit-keyframes neonyou {
    from {
      text-shadow: 
      0 0 10px var(--neon-white),
      0 0 20px var(--neon-white),
      0 0 30px var(--neon-white),
      0 0 40px var(--neon-green), 
      0 0 70px var(--neon-green),
      0 0 80px var(--neon-green),
      0 0 100px var(--neon-green), 
      0 0 150px var(--neon-green);
    }
    to {
      text-shadow: 0 0 5px var(--neon-white), 0 0 10px var(--neon-white), 0 0 15px var(--neon-white), 0 0 20px #FFDD1B, 0 0 35px #FFDD1B, 0 0 40px #FFDD1B, 0 0 50px #FFDD1B, 0 0 75px #FFDD1B;
    }
  }
  @-moz-keyframes neonyou {
    from {
      text-shadow: 0 0 10px var(--neon-white),
      0 0 20px var(--neon-white),
      0 0 30px var(--neon-white),
      0 0 40px var(--neon-green), 
      0 0 70px var(--neon-green),
      0 0 80px var(--neon-green),
      0 0 100px var(--neon-green), 
      0 0 150px var(--neon-green);
    }
    to {
      text-shadow: 0 0 5px var(--neon-white), 0 0 10px var(--neon-white), 0 0 15px var(--neon-white), 0 0 20px #FFDD1B, 0 0 35px #FFDD1B, 0 0 40px #FFDD1B, 0 0 50px #FFDD1B, 0 0 75px #FFDD1B;
    }
  }
  @keyframes neonyou {
    from {
      text-shadow: 
        0 0 3px var(--neon-white), 
        0 0 6px var(--neon-white),
        0 0 9px var(--neon-white),
        0 0 30px var(--neon-green), 
        0 0 45px var(--neon-green),
        0 0 60px var(--neon-green),
        0 0 75px var(--neon-green), 
        0 0 90px var(--neon-green);
    }
    to {
      text-shadow: 
        0 0 2px var(--neon-white),
        0 0 4px var(--neon-white),
        0 0 5px var(--neon-white),
        0 0 20px var(--neon-green),
        0 0 35px var(--neon-green),
        0 0 40px var(--neon-green),
        0 0 50px var(--neon-green),
        0 0 75px var(--neon-green);
    }
  }

  @keyframes neon {
    from {
      text-shadow:
        0 0 6px rgba(202,228,225,0.92),
        0 0 30px rgba(202,228,225,0.34),
        0 0 12px rgba(30,132,242,0.52),
        0 0 21px rgba(30,132,242,0.92),
        0 0 34px rgba(30,132,242,0.78),
        0 0 54px rgba(30,132,242,0.92);
    }
    to {
      text-shadow:
        0 0 6px rgba(202,228,225,0.98),
        0 0 30px rgba(202,228,225,0.42),
        0 0 12px rgba(30,132,242,0.58),
        0 0 22px rgba(30,132,242,0.84),
        0 0 38px rgba(30,132,242,0.88),
        0 0 60px rgba(30,132,242,1);
    }
  }

`;

export default function Logo() {
  return (
    <LogoStyles className="logo">
      <div className="inner">
        <h1>
          <span className="logo-word">Whatever</span>
          <span className="logo-word">Turns</span>
          <span className="you-light">YOU</span>
          <span className="logo-word">On</span>
        </h1>
      </div>
    </LogoStyles>
  )
}
